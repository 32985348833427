import React from "react";

const PDFIcon = ({ className }) => (
  <svg
    viewBox="0 0 475 266"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M269.362062,129 L458,129 C464.627417,129 470,134.372583 470,141 L470,249 C470,255.627417 464.627417,261 458,261 L17,261 C10.372583,261 5,255.627417 5,249 L5,141 C5,134.372583 10.372583,129 17,129 L31.3329796,129 L31.3329796,129"
        id="Path"
        className="stroke-current"
        strokeWidth="9"
        strokeLinecap="round"
      ></path>
      <path
        d="M298.432,223 L298.432,198.008 L310.136,198.008 C317,198.008 322.250667,196.38 325.888,193.124 C329.525333,189.868 331.344,185.336 331.344,179.528 C331.344,173.778667 329.584,169.246667 326.064,165.932 C322.544,162.617333 317.234667,160.96 310.136,160.96 L310.136,160.96 L290.424,160.96 L290.424,223 L298.432,223 Z M309.432,191.32 L298.432,191.32 L298.432,167.648 L309.432,167.648 C314.301333,167.648 317.806667,168.66 319.948,170.684 C322.089333,172.708 323.16,175.656 323.16,179.528 C323.16,183.282667 322.089333,186.186667 319.948,188.24 C317.806667,190.293333 314.301333,191.32 309.432,191.32 L309.432,191.32 Z M363.134,223 C369.998,223 375.879333,221.724 380.778,219.172 C385.676667,216.62 389.387333,213.012 391.91,208.348 C394.432667,203.684 395.694,198.213333 395.694,191.936 C395.694,185.6 394.432667,180.1 391.91,175.436 C389.387333,170.772 385.676667,167.193333 380.778,164.7 C375.879333,162.206667 369.998,160.96 363.134,160.96 L363.134,160.96 L343.422,160.96 L343.422,223 L363.134,223 Z M362.518,215.784 L351.43,215.784 L351.43,168 L362.518,168 C370.672667,168 376.876667,170.097333 381.13,174.292 C385.383333,178.486667 387.51,184.368 387.51,191.936 C387.51,199.504 385.368667,205.370667 381.086,209.536 C376.803333,213.701333 370.614,215.784 362.518,215.784 L362.518,215.784 Z M417.276,223 L417.276,195.192 L440.244,195.192 L440.244,188.944 L417.276,188.944 L417.276,167.472 L444.38,167.472 L444.38,160.96 L409.268,160.96 L409.268,223 L417.276,223 Z"
        id="PDF"
        className="fill-current"
        fillRule="nonzero"
      ></path>
      <g
        id="Group"
        transform="translate(66.000000, 5.000000)"
        className="stroke-current"
        strokeWidth="9"
      >
        <path
          d="M59.0596064,0 L164,0 C170.627417,-1.21743675e-15 176,5.372583 176,12 L176,206 C176,212.627417 170.627417,218 164,218 L12,218 C5.372583,218 8.11624501e-16,212.627417 0,206 L0,56.4876031 L0,56.4876031 L59.0596064,0 Z"
          id="Rectangle"
        ></path>
        <path
          d="M0,56.4876031 L48.0205747,56.4876031 C54.6479916,56.4876031 60.0205747,51.1150201 60.0205747,44.4876031 L60.0205747,1.8189894e-12 L60.0205747,1.8189894e-12"
          id="Path"
        ></path>
      </g>
      <g
        id="Group-2"
        transform="translate(109.000000, 71.000000)"
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth="9"
      >
        <line x1="45.5" y1="0" x2="45.5" y2="117.021516" id="Path-2"></line>
        <polyline
          id="Path-3"
          transform="translate(45.500000, 75.500000) rotate(-45.000000) translate(-45.500000, -75.500000) "
          points="14 44 14 107 77 107"
        ></polyline>
      </g>
    </g>
  </svg>
);
export default PDFIcon;
